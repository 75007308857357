import { AxiosResponse } from "axios";
import {
  BONUS_STATUS,
  GENDER,
  GAME_TRANSACTION_TYPE,
  USER_REQUEST_STATUS,
  BONUS_TYPE,
} from "~/config/enum";

export type IDataResponse<T> = AxiosResponse<{
  data: T;
}>;

export type IResponse<T> = {
    data: T;
    message: string
    success: boolean
}
export interface userAccess {
  username_or_email: string;
  password: string;
}

export interface userRegister {
  username: string;
  email: string;
  password: string;
  reference: string | null;
  affiliate_token: string | null;
  visitor_id: string;
  voucher_code: string | null;
}

export interface telegramData {
  user_data: string;
  reference: string | null;
  affiliate_token: string | null;
  visitor_id: string | null;
}

export interface userAccessSocial {
  provider_name: Record<string, any>;
  access_token: string;
  reference?: string | null;
  affiliate_token?: string | null;
  visitor_id?: string;
}

export interface email {
  email: string;
}

export interface resetPassword {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export interface listGame {
  pageLoaded: number;
  games: resCategoryGameList[];
  gameOrginal?: resCategoryGameList[];
}

export interface listCategoryGame {
  slug: string;
  total: number;
  listGame: listGame;
}

//request define schema
export interface emailVerificationRequest {
  id: string;
  hash: string;
}

export interface updatePersonalDetailRequest {
  personal_state_id?: number | null;
  receive_promotional_email?: boolean | null;
  email?: string;
  username?: string;
  first_name: string | null;
  last_name: string | null;
  birthday: string | null;
  gender: GENDER.FEMALE | GENDER.MALE | GENDER.OTHER;
  address: string;
  personal_city: string;
  zip_code: string;
  personal_country_code: string;
  phone_number: string;
  phone_code: string;
}

export interface updateInfoUserRequest {
  first_name: string;
  last_name: string;
  birthday: string;
  gender: GENDER.FEMALE | GENDER.MALE | GENDER.OTHER;
  email: string;
  address: string;
  personal_city: string;
  zip_code: number;
  personal_country_code: string;
  personal_state_id: number;
  phone_number: number;
  phone_code: string;
}

export interface gameLikeRequest {
  game_code: string;
  is_favorite: boolean;
}

export interface updatePasswordRequest {
  password_old: string | null;
  password: string | null;
  password_confirmation: string | null;
}

//response define schema
export interface resCategoryGameList {
  id: number;
  code: string;
  name: string;
  url_thumb: string;
  user_favorite_games_count: number;
  is_favorite: boolean;
  position: number;
  provider: string;
  can_play: string;
  color: string;
  provider_information?: {
    name: string;
    provider: string;
  };
}

export interface transactionHistoryParams {
  start_date: string;
  end_date: string;
  page: number;
  type?: string;
}

export interface promotionParams {
  slug: string;
  page?: number;
  limit?: number;
}

export interface coinHistoriesList {
  id: number;
  user_id: string;
  description: string | null;
  type: string | null;
  amount: number | null;
  bet_requirement_amount: number | null;
  bet_amount: string | null;
  win_amount: string | null;
  wallet_code: string | null;
  expired_at: string | null;
  action_at: string | null;
  status: USER_REQUEST_STATUS;
  created_at: string | null;
  updated_at: string | null;
  minus_amount: string | null;
}

export interface promotionCategories {
  id?: number;
  name: string;
  is_active?: boolean;
  position?: number;
  slug: string;
  created_at?: string;
  updated_at?: string;
}

export interface promotionDetail {
  id: number;
  prize_id: number | null;
  type: string;
  title: string;
  sub_title: string;
  description: string;
  image: string;
  content: string;
  term_and_condition: string;
  position: string;
  is_active: boolean;
  slug: string;
  created_at: string;
  updated_at: string;
  active_games: any;
  prize: prizeDetail;
}

export interface payloadPromotionResults {
  start_date: string;
  end_date: string;
  page: number;
  limit: number;
}

export interface promotionResult {
  id: number;
  amount: number | null;
  type: string;
  promotion_type: string;
  status: string;
  entity_wallet_code: string;
  game_code: string;
  game_name: string;
  fs_round: number;
  used_fs_round: number;
  fs_amount: string;
  created_at: string;
  updated_at: string;
}

export interface prizeDetail {
  end_active_time: string;
  entity_wallet_code: string;
  id: number;
  min_bet: string;
  opted_in: boolean;
  start_active_time: string;
  prize_generation_id: number;
}

export interface promotionList {
  description: string;
  image: string;
  position: number;
  sub_title: string;
  title: string;
  slug: string;
  id: number
}

export interface pageDetail {
  id: number;
  slug: string;
  title: string;
  excerpt: string;
  body: string;
  image: string;
  meta_description: string;
  meta_keywords: string;
  is_active: boolean;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
}

export interface createContactPayload {
  first_name: string;
  last_name: string;
  email: string;
  category_id: number | null;
  subject: string;
  message: string;
}

export interface changeSelectedWalletPayload {
  code: string;
}

export interface imageSlider {
  id: string;
  button: string;
  title: string;
  content: string;
  srcPc: string;
  srcSp: string;
  urlRedirect: string;
}

export interface wallet {
  allow_withdrawal: boolean;
  balance: number;
  bonus_balance: string;
  entity_wallet_code: string;
  coin_image: string;
  is_selected: boolean;
  name?: string;
  real_balance: string;
  id: number;
}

export interface socialUrls {
  social_media_facebook: string;
  social_media_instagram: string;
  social_media_reddit: string;
  social_media_x: string;
  social_media_tiktok: string;
  social_media_telegram: string;
}

export interface updateWalletSettingPayload {
  wallet_currency: string | null;
  hide_zero_balance: boolean;
}

export interface entityWallet {
  id: number;
  name: string;
  code: string;
  min_topup: string;
  max_topup: string;
  allow_withdrawal: boolean;
  coin_image: string;
  position: number;
  is_active: boolean;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  withdrawal_fee: string;
  networks: any[];
}

export interface userWalletAddress {
  id: number;
  user_id: number;
  entity_wallet_code: string;
  type: string;
  wallet_address: string;
  wallet_label: string | null;
  qr_code: string | null;
  profile_id: number | null;
  kind: string;
  network_code: string;
  payment_request: string | null;
  payment_hash: string | null;
  x_address: string | null;
  public_address: string | null;
  destination_tag: number | null;
  amount: number | null;
}

export interface cashbackInfo {
  cashbackList: any[];
  claimTime: string;
  expiredTime: string;
  readyToClaim: boolean;
}

export interface gameHistoriesList {
  created_at: string;
  action_sub_type: GAME_TRANSACTION_TYPE;
  amount: string;
  entity_wallet_code: string;
  real_balance: string;
  bonus_balance: string;
  id: number;
  game_code: string;
  game: {
    code: string;
    name: string;
    can_play: boolean;
  };
}

export interface withdrawalPayload {
  received_amount: number;
  entity_wallet_code: string;
  wallet_address?: string;
  kind: string;
  payment_request?: string;
  destination_tag?: number | null;
}

export interface lightnightPayload {
  amount: string;
}

export interface bonusData {
  id: number;
  type: BONUS_TYPE;
  entity_wallet_code: string;
  start_claim_time: string;
  end_claim_time: string;
  claimed_at: string;
  start_active_time: string;
  end_active_time: string;
  is_claim: number;
  is_active: boolean;
  transfer_amount: string;
  bet_requirement_amount: string;
  bet_amount: string;
  bet_left_amount: number;
  fs_round: string;
  fs_amount: number;
  used_fs_round: string;
  is_deposit_requirement: boolean;
  message: string;
  title: string;
  finished_at: string;
  percentage_deposit: number;
  bet_requirement: number;
  min_deposit_amount: string;
  max_deposit_amount: string;
  added_bonus_amount: string;
  game_code: string;
  game_name: string;
  read_at: string;
  valid_coins: any[];
  created_at: string;
  status: BONUS_STATUS;
  received_on: string;
  days?: number;
  hours?: number;
  mins?: number;
}

export interface gameProvider {
  provider: string;
  image: string;
  number_games?: number;
  is_blocked?: boolean;
}

export interface listFilterParams {
  category_slug?: string;
  page?: number;
  keyword?: string;
  providers?: any[];
  provider?: string;
  categoryIds?: any[];
}

export interface blogFilterParams {
  blog_tags?: string[];
  keyword?: string;
  page?: number;
  limit?: number;
  ignore_blog?: number;
}

export interface blogTag {
  name: string;
  slug: string;
  position: number;
}

export interface blogList {
  data: blog[];
  current_page: number;
  total: number;
}

export interface blog {
  title: string;
  slug: string;
  image: string;
  description?: string;
  content: string;
  is_trending: boolean;
  created_at: string;
  blog_tags: blogTag[];
  id?: number;
}

export interface breadcrumb {
  title: string;
  href?: string;
  disabled?: boolean;
}

export interface tournamentSchema {
  id: number;
  slug: string;
  tournament_id: number;
  end_active_time: string;
  image: string;
  title: string;
  total_cash_amount: string;
  total_free_spin_amount: string;
  opted_in: boolean;
}

export interface leaderboardSchema {
  index: number;
  user_id: number;
  user_name: string;
  level_image: string;
  point: number;
  type?: string;
  amount?: number;
  fiat_amount?: number;
  fs_round?: number;
  fs_amount?: number;
  entity_wallet_code: string;
}

export interface tournamentListSchema {
  data: tournamentSchema[];
  current_page: number;
  total: number;
}

export interface IEntityWallet {
  id: number;
  code: string;
  min_topup: string;
  max_topup: string;
  allow_withdrawal: boolean;
  coin_image: string;
  position: number;
  is_active: boolean;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  withdrawal_fee: string;
  networks: INetwork[];
}

export interface INetwork {
  network_name: string;
  kind: string;
  network_code: string;
}

export interface IGameCategories {
  id: number;
  name: string;
  position: number;
  image: string;
  slug: string;
  created_at: string;
  updated_at: string;
  display_sections: string[];
}

export interface IGame {
  id: number;
  code: string;
  name: string;
  url_thumb: string;
  color: string;
  provider: string;
  hidden_when_bonus: boolean;
  is_active: boolean;
  position: number;
  can_play: string;
  provider_information: {
    provider: string;
    name: string;
  };
}
export interface IGameCategoryWithGame extends IGameCategories {
  games: IGame[];
}

export enum NavbarEnum {
  HOME = 1,
  FAVORITES = 2,
  CHALLENGES = 22,
  PROMOTIONS = 3,
  TOURNAMENTS = 4,
  PROVIDERS = 11,
  VIP = 12,
  VPN_INFO = 23,
  AFFILIATE = 14,
  LIVE_SUPPORT = 15,
  PLAY_AGAIN = 16,
  YOUR_BIGGEST_WIN = 17,
  PROFILE = 18,
  MY_BONUS = 19,
  WALLET = 20,
  TRANSACTIONS = 21,
}
export interface INavBarItem {
  id: NavbarEnum;
  name: string;
  url: string;
  callback?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  image?: string;
}

export interface IImageSlider {
  id: number;
  image_url: string;
  position: number;
  redirect_url?: string;
  title?: string;
  description?: string;
  button_text?: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  redirect_full_url?: string;
  display_sections: string[];
  display_statuses: string[];
}

export interface IMostGameWins {
  id: number;
  code: string;
  name: string;
  url_thumb: string;
  color: string;
  provider: string;
  hidden_when_bonus: boolean;
  is_active: boolean;
  is_favorite: boolean;
  game_code: string;
  max_amount: string;
  max_fiat_amount: string;
  entity_wallet_code: string;
  reference_bet_amount: string;
  multiplier: string;
  can_play: string;
  provider_information: {
    provider: string;
    name: string;
  };
  level_image?: string;
}

export interface promotionSchema {
  id: number;
  slug: string;
  title: string;
  sub_title: string;
  image: string;
  description: string;
  content: string;
  button_text: string;
  term_and_condition: boolean;
}

export enum GameType {
  LOBBY = "in_lobby",
  LIVE_CASINO = "in_live_casino",
}

export interface IPromotionCategory {
    id: number
    slug: string
    name: string
}
